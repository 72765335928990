import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const NaturalHealingLandingPage = () => (
  <PhysicianLandingPage
    physician="Denise Wiesner"
    institution="Natural Healing Acupuncture"
    referralCode="NATURALHEALING"
    physicianURL="https://naturalhealingacupuncture.com/"
  />
)

export default NaturalHealingLandingPage
